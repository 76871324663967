<template>
  <div style="text-align: center">
    <div class="loginBox">
      <!--      <div style="position: relative;left: 160px;bottom: 0px">-->
      <!--        <el-select v-model="languageValue" @change="changeLanguage">-->
      <!--          <el-option-->
      <!--              v-for="item in languageOptions"-->
      <!--              :key="item.value"-->
      <!--              :label="item.label"-->
      <!--              :value="item.value"-->
      <!--          >-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
      <h4>{{ $t('common.title') }}</h4>
      <el-form class="login-form" ref="loginForm" :model="loginForm" :rules="loginRules">
        <el-form-item prop="username">
          <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.username')"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              :placeholder="$t('login.password')"
              @click.native.prevent="handleLogin"
          >
          </el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button
              :loading="loading"
              size="medium"
              type="primary"
              style="width:100%;"
              @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">{{ $t('login.login') }}</span>
            <span v-else>{{ $t('login.login') }}...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {login} from '@/api/login.js'

export default {
  name: "Index",
  data() {
    const ruleUsername = (rule, value, callback) => {
      if (this.loginForm.username === '' || this.loginForm.username === undefined) {
        callback(new Error(this.$t('login.ruleUsername').toString()))
      } else {
        callback()
      }
    }
    const rulePassword = (rule, value, callback) => {
      if (this.loginForm.password === '' || this.loginForm.password === undefined) {
        callback(new Error(this.$t('login.rulePassword').toString()))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          {required: true, trigger: "blur", validator: ruleUsername}
        ],
        password: [
          {required: true, trigger: "blur", validator: rulePassword}
        ],
      },
      loginUser: '',
      languageValue: '',
      languageOptions: [],
      menuType: 0,
    }
  },
  created() {
    // console.log(process.env.NODE_ENV)
    this.$i18n.locale = 'en'
    // this.$i18n.locale = 'zh'
    localStorage.setItem('locale', this.$i18n.locale)
    //最开始请求的时候看缓存是什么状态
    if (this.$i18n.locale === 'zh') {
      this.languageValue = '中文简体'
      this.languageOptions = [{value: 'en', label: 'English'}]
    } else {
      this.languageValue = 'English'
      this.languageOptions = [{value: 'zh', label: '中文简体'}]
    }
  },
  mounted() {
    //let token = sessionStorage.getItem("token");
    //if (token) {
    //  this.$router.push("/formsPanel")
    //} else {
    console.log("window.location.href:" + window.location.href);
    const account = this.$route.query.account;
    const password = this.$route.query.password;
    console.log(this.$route.query)
    const menuType = this.$route.query.menuType;
    if (menuType !== undefined) {
      this.menuType = menuType;
    }
    console.log("menuType", menuType, this.menuType)
    if (account !== null && password != null) {
      this.loginForm.username = account;
      this.loginForm.password = password;
      this.handleLogin();
    }
    //}
  },
  methods: {
    changeLanguage(type) {
      // 此处做了语言选择记录，存在localStorage中，这里的作用只有一个当我重新请求页面
      //的时候先取localStorage的记录值
      localStorage.setItem('locale', type)
      this.$i18n.locale = type // 修改页面需要显示的语言
      if (this.$i18n.locale === 'zh') {
        this.languageValue = '中文简体'
        this.languageOptions = [{value: 'en', label: 'English'}]
      } else {
        this.languageValue = 'English'
        this.languageOptions = [{value: 'zh', label: '中文简体'}]
      }
    },
    getQueryString(name) {
      const url_string = window.location.href; // window.location.href
      const url = new URL(url_string);
      return url.searchParams.get(name);
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          login(this.loginForm.username, this.loginForm.password).then(res => {
            sessionStorage.setItem("token", res.data)
            if (Number(this.menuType) === 0) {
              this.$router.push("/formsPanel")
            } else {
              this.$router.push("/userFace")
            }
          }).catch(() => {
            this.loading = false;
          });
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.loginBox {
  width: 500px;
  height: 200px;
  border-radius: 3px;
  box-shadow: 0px 0px 7px #cdcdcd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 25px 100px 25px;
  background: #ffffff;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }
}


h4 {
  color: #38adff;
}
</style>
