import request from '@/api/request.js';

// 登录方法
export function login(username, password) {
    const data = {username, password}
    return request({
        url: '/admin/auth',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}
